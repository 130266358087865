exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-page-jsx-content-file-path-content-pages-english-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/vercel/path0/content/pages/english.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-content-pages-english-mdx" */),
  "component---src-templates-page-jsx-content-file-path-content-pages-forside-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/vercel/path0/content/pages/Forside.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-content-pages-forside-mdx" */),
  "component---src-templates-page-jsx-content-file-path-content-pages-hvaskjer-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/vercel/path0/content/pages/hvaskjer.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-content-pages-hvaskjer-mdx" */),
  "component---src-templates-page-jsx-content-file-path-content-pages-innsamling-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/vercel/path0/content/pages/innsamling.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-content-pages-innsamling-mdx" */),
  "component---src-templates-page-jsx-content-file-path-content-pages-kontaktoss-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/vercel/path0/content/pages/kontaktoss.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-content-pages-kontaktoss-mdx" */),
  "component---src-templates-page-jsx-content-file-path-content-pages-privacy-policy-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/vercel/path0/content/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-content-pages-privacy-policy-mdx" */),
  "component---src-templates-page-jsx-content-file-path-content-pages-regler-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/vercel/path0/content/pages/regler.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-content-pages-regler-mdx" */),
  "component---src-templates-page-jsx-content-file-path-content-pages-skeiv-dag-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/vercel/path0/content/pages/skeiv-dag.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-content-pages-skeiv-dag-mdx" */),
  "component---src-templates-page-jsx-content-file-path-content-pages-vaare-maal-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/vercel/path0/content/pages/vaare-maal.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-content-pages-vaare-maal-mdx" */)
}

